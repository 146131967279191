<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Add a BrandProfile</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="onSubmitForm" @keyup.enter.native="onSubmitForm">
                <!-- TODO: show available brandprofiles from linked account at brandprofile.org; for now we just let user enter alias directly -->
                <!-- <v-select v-if="emailAudienceId"
                    ref="emailContactIdInput"
                    v-model="newEmailContactId"
                    label="Select email contact"
                    :items="newEmailContactChoices"
                    color="teal darken-2"
                    required
                    outlined
                    >
                </v-select>
                <v-select v-if="emailContactId"
                    ref="emailAudienceIdInput"
                    v-model="newEmailAudienceId"
                    label="Select email audience"
                    :items="newEmailAudienceChoices"
                    color="teal darken-2"
                    required
                    outlined
                    >
                </v-select> -->
                <v-text-field
                    label="BrandProfile Alias"
                    v-model="editableBrandProfileAlias"
                    outlined
                    dense
                >
                </v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                    <span>Create</span>
                </v-btn>
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    data: () => ({
        submitActionTimestamp: null,

        editableBrandProfileAlias: null,
        /*
        emailAudienceId: null,
        emailContactId: null,

        newEmailAudienceChoices: [],
        newEmailContactChoices: [],

        newEmailAudienceId: null,
        newEmailContactId: null,
        */
    }),

    computed: {
        isFormComplete() {
            return typeof this.editableBrandProfileAlias === 'string' && this.editableBrandProfileAlias.length > 0;
        },
    },

    methods: {
        onSubmitForm() {
            if (Number.isInteger(this.submitActionTimestamp) && this.submitActionTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitActionTimestamp = Date.now();
            if (!this.isFormComplete) {
                return;
            }
            this.createBrandProfile();
        },
        cancel() {
            this.$emit('cancel');
        },
        // TODO: when the add fails, get a reason from the server  -- at least we should be able to tell the user if the contact is already in the audience, or if there's a temporary server issue and to try again later, or if it's something else and they should contact customer support
        async createBrandProfile() {
            try {
                this.error = false;
                this.$store.commit('loading', { createBrandProfile: true });
                console.log('createBrandProfile');
                const request = {
                    alias: this.editableBrandProfileAlias,
                };
                const response = await this.$client.account(this.$route.params.accountId).brandProfile.create(request);
                console.log('createBrandProfile response: %o', response);
                const { id, error } = response;
                if (id) {
                    this.$emit('created', { ...request, id });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add brand profile' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add brand profile' });
                    this.error = 'Request failed';
                }
            } catch (err) {
                console.error('Failed to add brand profile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add brand profile' });
            } finally {
                this.$store.commit('loading', { createBrandProfile: false });
            }
        },
        /*
        async loadEmailAudienceList() {
            try {
                this.$store.commit('loading', { loadEmailAudienceList: true });
                // const match = { email_contact_id: request.emailContactId };
                const match = {}; // TODO: allow user to type in a partial name and search for it
                const response = await this.$client.account(this.$route.params.accountId).emailAudience.search(match);
                if (response?.list) {
                    this.newEmailAudienceChoices = response.list.map((item) => ({ text: item.label, value: item.id }));
                }
            } catch (err) {
                console.error('loadEmailAudienceList failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailAudienceList: false });
            }
        },
        async loadEmailContactList() {
            try {
                this.$store.commit('loading', { loadEmailContactList: true });
                // const match = { email_audience_id: request.emailAudienceId };
                const match = {}; // TODO: allow user to type in a partial name and search for it
                const response = await this.$client.account(this.$route.params.accountId).emailContact.search(match);
                if (response?.list) {
                    this.newEmailContactChoices = response.list.map((item) => ({ text: item.email, value: item.id }));
                }
            } catch (err) {
                console.error('loadEmailContactList failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailContactList: false });
            }
        },
        */
    },
    mounted() {
        // if (this.$route.params.emailAudienceId) {
        //     this.emailAudienceId = this.$route.params.emailAudienceId;
        //     this.loadEmailContactList();
        // }
        // if (this.$route.params.emailContactId) {
        //     this.emailContactId = this.$route.params.emailContactId;
        //     this.loadEmailAudienceList();
        // }
    },
};
</script>
